import React, { useState, useEffect } from "react";
import { useUser } from "../../../context/User/UserContext";

import Typography from "../../style/Typography";
import Button from "../../style/Button";


import { IoSettingsSharp } from "react-icons/io5";
import { CiCalendarDate } from "react-icons/ci";

const EditUserDocsForm = ({ model, onSave, onCancel }) => {
  const [name, setName] = useState(model?.name || "");
  const [category, setCategory] = useState(model?.category || "");
  const [subcategory, setSubcategory] = useState(model?.subcategory || "");
  const [access, setAccess] = useState(model?.access || "");
  const [note, setNote] = useState(model?.note || "");

  const { user } = useUser(); // Accéder aux données utilisateur depuis le contexte
  const categories = user?.metadata?.categories || []; // Récupérer les catégories utilisateur

  console.log("model :", model);
  console.log("User data :", user);

  // Synchroniser les données initiales
  useEffect(() => {
    setName(model?.name || "");
    setCategory(model?.category || "");
    setSubcategory(model?.subcategory || "");
    setAccess(model?.access || "");
    setNote(model?.note || "");
  }, [model]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedData = {
      id: model?.id,
      name,
      category,
      subcategory,
      access,
      note,
    };
    onSave(updatedData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 px-4">
      {/* En-tête */}
      
    {/* Titre + icône dans un cercle */}
    <div className="border-b py-4 flex items-center space-x-3">
        {/* Cercle contenant l'icône */}
        <div className="w-12 h-12 rounded-full bg-primary flex items-center justify-center">
            <IoSettingsSharp className="text-white" />
        </div>

        <Typography variant="caption2"  theme="text-gray-700">
            {model?.name}
        </Typography>

        {/* Date de création */}
        <Typography variant="body-sm" theme="gray-400" className="flex items-center space-x-1">
            <CiCalendarDate /> 
            <span className="text-gray-500">{model?.created_at || "Non spécifiée"}</span>
        </Typography>
        
    </div>


      {/* Nom du modèle */}
      <div className="">
        <Typography
            variant="body-base"
            theme="gray-600"
            weight="medium"
        >
            <span className="text-primary">*</span> Nom du modèle
        </Typography>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
          required
        />
        <Typography
            variant="caption4"
            theme="gray-400"
            weight="bold"
            className="mt-1"
        >
            Saisissez le nom de votre modèle.
        </Typography>
        <p className="text-sm text-gray-500 mt-1"></p>
      </div>

      {/* Catégorie */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Catégorie</label>
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">Sélectionner une catégorie</option>
          {categories.map((cat, index) => (
            <option key={index} value={cat}>
              {cat}
            </option>
          ))}
        </select>

        <Typography 
            variant="caption3" 
            theme="gray-400"
            weight="bold"
            className="mt-1"
        >
            Séléctionnez une catégorie.
        </Typography>
      </div>

      {/* Catégorie */}
      <div>
        <Typography
            variant="body-base"
            theme="gray-600"
            weight="medium"
        >
            Sous-Catégorie
        </Typography>

        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">Sélectionner une catégorie</option>
          <option value="RH">Ressources humaines</option>
          <option value="Finance">Finance</option>
          <option value="Légal">Légal</option>
        </select>
        <Typography 
            variant="caption3" 
            theme="gray-400"
            weight="bold"
            className="mt-1"
        >
            Séléctionnez une sous-catégorie.
        </Typography>
      </div>

      {/* Note */}
      <div className="border-b">
        <Typography
            variant="body-base"
            theme="gray-600"
            weight="medium"
        >
            Note
        </Typography>
        <textarea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder={model?.description}
          rows={4}
        />
      </div>

    </form>
  );
};

export default EditUserDocsForm;
