import React, { useState } from 'react';
import content from '../../../../assets/jsonfile/howItWorksContent.json';
import TutorialSection from '../../../style/TutorialSection';
import { Img } from 'react-image';
import { IoCloseSharp } from "react-icons/io5";
import Button from '../../../style/Button';
import Typography from '../../../style/Typography';
import StickyWrapper from '../../../style/StickyWrapper';

const TutorialContainer = () => {
    // Chargement des gifs
    const gifContext = require.context("../../../../assets/gifs/howitworks", false, /\.gif$/);
    const gifs = gifContext.keys().reduce((acc, path) => {
        const fileName = path.replace("./", "");
        acc[fileName] = gifContext(path);
        return acc;
    }, {});

    // État pour la modale
    const [isOpen, setIsOpen] = useState(false);
    const [selectedGif, setSelectedGif] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // État pour le sommaire mobile

    const openModal = (gif) => {
        setSelectedGif(gif);
        setIsOpen(true);
    };

    const closeModal = () => {
        setSelectedGif(null);
        setIsOpen(false);
    };

    return (
        <div className="flex flex-col md:flex-row scroll-smooth">
            {/* Sommaire interactif */}
            <StickyWrapper top={20}>
                <div className="w-[200px] md:min-w-[170px] md:max-w-[220px] md:border-r md:border-gray-300 pr-6">
                    {/* Bouton pour mobile */}
                    <button
                        className="md:hidden bg-primary-600 text-white px-4 py-2 rounded mb-4"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                    >
                        {isMenuOpen ? "Fermer le sommaire" : "Ouvrir le sommaire"}
                    </button>

                    {/* Contenu du sommaire */}
                    <div
                        className={`${
                            isMenuOpen ? "block" : "hidden"
                        } md:block bg-gray-100 md:bg-transparent p-4 md:p-0`}
                    >
                        <Typography variant="lead" weight="bold" className="mb-8 text-center md:text-left">
                            Sommaire
                        </Typography>
                        <ul className="space-y-4">
                            {content.map((section) => (
                                <li key={section.id}>
                                    <Typography
                                        variant="body-sm"
                                        className="hover:text-primary-600 hover:font-bold text-center md:text-left"
                                    >
                                        <a
                                            href={`#${section.title.toLowerCase().replace(/\s+/g, '-')}`}
                                        >
                                            {section.title}
                                        </a>
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </StickyWrapper>


    
            {/* Section principale */}
            <div className="flex-grow md:pl-8 w-full">

                <Typography variant="lead" weight='bold' className="mb-4 text-center">
                    Tutoriel
                </Typography>
                
                {content.map((section, index) => (
                    <TutorialSection 
                        key={section.id}
                        section={{ ...section, last: index === content.length - 1 }}
                        gifs={gifs}
                        openModal={openModal}
                    />
                ))}
    
                {/* Modale pour afficher le GIF en grand */}
                {isOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
                        <div className="relative border border-primary rounded-lg w-full max-w-screen-md sm:max-w-3xl lg:max-w-4xl bg-white rounded-lg overflow-hidden">
                            {/* Bouton pour fermer */}
                            <div className="absolute top-2 right-2 z-10">
                                <Button
                                    variant="ico"
                                    icon={IoCloseSharp}
                                    iconTheme="accent"
                                    iconSize={28}
                                    size="md"
                                    onClick={closeModal}
                                    className="shadow-lg hover:opacity-80"
                                />
                            </div>
                            {/* GIF agrandi */}
                            <div className="flex items-center justify-center">
                                <Img
                                    src={selectedGif}
                                    alt="Aperçu du GIF"
                                    className="w-full h-auto rounded-lg"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
    
};

export default TutorialContainer;
