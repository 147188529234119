import React from 'react';
import Typography from '../../../components/style/Typography';
import { HomePageContainer } from '../../../components/Containers/HomePageContainer';
import { Img } from 'react-image';
import step1 from '../../../assets/svg/steps/step1.png';
import step2 from '../../../assets/svg/steps/step2.png';
import step3 from '../../../assets/svg/steps/step3.png';
import Loader from '../../../components/style/Loader';

const steps = [
    {
        title: "Choisissez un modèle adapté",
        description: "Parcourez nos modèles prêts à l'emploi ou importez vos propres documents pour commencer en toute simplicité.",
        image: step1,
    },
    {
        title: "Complétez les champs",
        description: "Remplissez les champs avec vos informations pour générer un document conforme et précis.",
        image: step2, 
    },
    {
        title: "Téléchargez le document",
        description: "Générez votre document en PDF ou DOCX, prêt à être utilisé.",
        image: step3,
    },
];

const HomePageHowItWorks = () => {
    return (
        <section className="bg-gradient-to-b from-white to-gray-50 py-16">
            <HomePageContainer >
                <div className="text-center mb-12">
                    <Typography variant="h2" theme="primary" className="drop-shadow-lg">
                        Vos documents prêts en 3 étapes simples
                    </Typography>
                </div>

                {/* Timeline */}
                <div className="relative flex flex-col items-center space-y-12 md:space-y-0">
                    {/* Ligne verticale */}
                    <div className="absolute w-0.5 h-full bg-gray-200 left-1/2 transform -translate-x-1/2 hidden md:block"></div>
                    {/* Étapes */}
                    {steps.map((step, index) => (
                        <div
                            key={index}
                            className={`flex flex-col md:flex-row items-center w-full space-y-8 md:space-y-16 ${
                                index % 2 === 0 ? 'md:flex-row-reverse' : ''
                            }`}
                        >
                            {/* Contenu de l'étape */}
                            <div className="w-full md:w-1/2 px-8 text-center md:text-left space-y-4">
                            <Typography
                                variant="h4"
                                weight="bold"
                            >
                                {step.title}
                            </Typography>
                            <Typography
                                variant="body-lg"
                                theme="gray-600"
                                className="mb-8"
                            >
                                {step.description}
                            </Typography>

                            </div>

                            {/* Cercle numéroté */}
                            <div className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center bg-primary-600 text-white font-bold rounded-full z-10 shadow-lg">
                                {index + 1}
                            </div>

                            {/* Image */}
                            <div className="w-full md:w-1/2 px-8">
                                <div className="w-full border-2 border-primary-400 hover:border-blue-500 aspect-[16/9] rounded-md overflow-hidden bg-gray-200">
                                    <Img
                                        src={step.image}
                                        alt={step.title}
                                        loader={<div className="flex items-center justify-center h-full bg-gray-200"><Loader size="md" color="primary" /></div>}
                                        unloader={<Typography variant="caption3" theme="gray-600">Image non disponible</Typography>}
                                        className="w-full h-full object-cover transition-transform duration-300 ease-in-out hover:scale-105"
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </HomePageContainer>
        </section>
    );
};

export default HomePageHowItWorks;
