import React, { useState, useEffect } from "react";
import { Modal } from "flowbite-react";
import { FcGoogle } from "react-icons/fc";
import { useModal } from "../../../context/Login/ModalContext";
import { useUser } from "../../../context/User/UserContext";
import { useNavigate } from 'react-router-dom';
import Button from '../../style/Button';

import Typography from "../../style/Typography";

import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';

function LoginModal() {
    const { isModalOpen, closeModal } = useModal();
    const { user, login } = useUser();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    // Liste des utilisateurs de test
    const testUsers = [
        { email: 'maude@example.com', password: '123', name: 'Maude', global_role: 'USER' },
        { email: 'flora@example.com', password: '654123', name: 'Flora', global_role: 'ADMIN' },
        { email: 'test@example.com', password: '123', name: 'Test User', global_role: 'USER' },
        { email: 'admin@example.com', password: '654123', name: 'Admin User', global_role: 'ADMIN' },
        { email: 'jl@example.com', password: '123', name: 'Jean-Luc', global_role: 'ADMIN' },
    ];


    useEffect(() => {
        // console.log('useEffect déclenché, user :', user);
        if (user) {
            closeModal();
            navigate('/workspace');
        }
    }, [user, closeModal, navigate]);

    const handleLogin = (e) => {
        e.preventDefault();
        
        // Recherche de l'utilisateur dans la liste des utilisateurs de test
        const foundUser = testUsers.find(
            (u) => u.email === email && u.password === password
        );

        if (foundUser) {
            login(foundUser); // Met à jour le contexte avec les informations de l'utilisateur
            setError(''); // Réinitialise les erreurs
            // Le useEffect se chargera de fermer la modal et de rediriger
        } else {
            setError('Email ou mot de passe incorrect');
        }
    };

    const handleLoginSuccess = async (credentialResponse) => {
        const { credential } = credentialResponse;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/google`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: credential,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Authentication failed:', errorData);
                setError("La connexion a échoué. Veuillez réessayer.");
                return;
            }

            const data = await response.json();

            //console.log('Données utilisateur reçues :', data.user);

            // Vérifiez si 'global_role' est présent
            if (!data.user.global_role) {
                console.error('La propriété global_role est manquante dans data.user');
            }

            // Stocker le token JWT (attention aux questions de sécurité)
            localStorage.setItem('access_token', data.access_token);
            // Mettre à jour le contexte utilisateur
            login(data.user);
        } catch (error) {
            console.error('An error occurred:', error.message);
        }
    };

    // Gestion de l'erreur de Google Login
    const handleLoginError = () => {
        console.error("Google Login Failed");
        setError("La connexion Google a échoué. Veuillez réessayer.");
    };

    return (
        <Modal show={isModalOpen} onClose={closeModal} size="md">
            <Modal.Header>
                <Typography variant="h4" weight="bold">
                    Bienvenue sur Bara Doc
                </Typography>
            </Modal.Header>
            
            <Modal.Body>
                <div className="text-left">
                    <p className="mb-2">
                        Accédez à votre compte en quelques secondes. <br/>
                        <span className="text-xs text-gray-500 italic">
                            Bara Doc est actuellement en version BETA.*
                        </span>
                    </p>

                    <form onSubmit={handleLogin}>
                        <input
                            type="email"
                            placeholder="name@company.com"
                            className="border w-full p-2 mb-2"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            className="border w-full p-2 mb-4"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <Button 
                            variant='primary' 
                            size='sm' 
                            type="submit"  // Indique que le bouton est de type "submit"
                            className="w-full"  // Pour le rendre large comme le bouton d'origine
                        >
                            Se connecter à mon compte
                        </Button>
                    </form>
                    <div className="flex justify-center items-center text-center mt-4 mb-4">
                        <span className="text-gray-500">ou</span>
                    </div>
                    <GoogleLogin
                        onSuccess={handleLoginSuccess}
                        onError={handleLoginError}
                    />
                    {error && (
                        <p className="text-red-500 text-sm mt-2">{error}</p>
                    )}
                    <p className="text-xs text-gray-500 mt-4">
                        Vous n’avez pas encore de compte ? <br/>
                        Pour demander un accès à Bara Doc : <a 
                            href="https://tally.so/r/wvEyzA" 
                            className="underline"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            cliquez ici
                        </a>.
                    </p>
                </div>
            </Modal.Body>
        </Modal>
            
        );
}

export default LoginModal;
