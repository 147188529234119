import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer';
import Loader from '../../components/style/Loader';
import { useUser } from '../../context/User/UserContext';
import { useNavigate } from 'react-router-dom';

import { HomePageContainer } from '../../components/Containers/HomePageContainer';

import HomePageHero from '../Homepage/HomePageHero';
import HomePageBenefits from '../Homepage/HomePageBenefits';
import HomePagePain from '../Homepage/HomePagePain';
import HomePageHowItWorks from '../Homepage/HomePageHowItWorks';
import HomePageTestimonials from '../Homepage/Testimonials';
import HomePagePricing from '../Homepage/HomePageTarifs';
import HomePageFAQ from '../Homepage/HomePageFAQ';

import { AnimatedSection } from '../../components/style/Animations/AnimatedSection';

function Home() {

    const { user, isLoading } = useUser();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!isLoading && user) {
            navigate('/workspace');
        }
    }, [isLoading, user, navigate]);

    if (isLoading) {
        return <div className='flex items-center justify-between'>
                    <Loader/>
                </div>; // Optionnel : Ajoutez un écran de chargement
    }


    return (
        <div className="flex flex-col py-4">
            <HomePageHero />
            <HomePageHowItWorks />
            <HomePageBenefits />
            {/*<HomePageTestimonials />*/}
            <HomePagePain />
            {/*<HomePagePricing />*/}
            <HomePageFAQ />
            {/*<HomePageCTA/>*/}
            <Footer />

        </div>
    );
    
}

export default Home;
