import React, { useState } from 'react';
import Typography from '../../../components/style/Typography';
import Button from '../../../components/style/Button';
import { HomePageContainer } from '../../../components/Containers/HomePageContainer';
import { useNavigate } from 'react-router-dom';
import { Img } from "react-image"

import pain1 from '../../../assets/svg/pain1.jpg';


const painPoints2 = [
    {
        title: "Des heures perdues sur des tâches répétitives",
        description: "Remplir manuellement chaque document, rechercher des informations dispersées, copier-coller sans fin... Ces processus chronophages vous détournent de votre vrai métier.",
        image: pain1,
    },
    {
        title: "Une charge mentale constante",
        description: "Entre les deadlines, les documents urgents et les imprévus, votre stress monte. Vous finissez épuisée, avec le sentiment de ne jamais en faire assez.",
        image: pain1,
    },
    {
        title: "La difficulté de déconnecter",
        description: "Votre travail vous suit partout : le soir, le week-end, et parfois même pendant les vacances. Ces documents qui s’accumulent et ces tâches à finaliser restent dans un coin de votre tête, vous empêchant de profiter pleinement de votre temps libre.",
        image: pain1,
    },
    {
        title: "Une pression à toujours tout bien faire",
        description: "En tant que RH freelance, chaque erreur dans un document peut impacter votre crédibilité. Cette pression constante à tout vérifier, à tout relire, et à tout perfectionner vous laisse peu de place pour respirer et vous sentir sereine.",
        image: pain1,
    },
    {
        title: "Un équilibre vie pro/perso difficile à atteindre",
        description: "Jongler entre vos responsabilités professionnelles et votre vie personnelle devient un défi quotidien. Les tâches administratives prennent tellement de temps et d’énergie qu’il est difficile de trouver des moments pour soi ou sa famille.",
        image: pain1,
    }
];


const painPoints = [
    {
        title: "Trop de copier-coller et de perte de temps",
        description: "Chaque document nécessite des modifications manuelles répétitives : copier des informations, ajuster des détails et vérifier chaque élément. Ces tâches grignotent vos journées.",
        image: pain1,
    },
    {
        title: "Gérer plusieurs versions de documents est un casse-tête",
        description: "Perdre du temps à chercher la dernière version ou risquer d'envoyer un document obsolète qui peut provoquer des erreurs ou des malentendus coûteux.",
        image: pain1,
    },
    {
        title: "Personnaliser vos documents est une vraie corvée",
        description: "Chaque situation RH est différente, mais ajuster chaque document à la main est fastidieux et souvent source d’oublis, rendant le processus frustrant et peu fiable.",
        image: pain1,
    },
    {
        title: "Une erreur dans un document peut coûter cher",
        description: "Un mauvais prénom, une faute dans une date ou un document mal formaté peut avoir des conséquences graves sur votre crédibilité professionnelle.",
        image: pain1,
    },
    {
        title: "Les tâches administratives empiètent sur vos priorités",
        description: "Au lieu de vous concentrer sur des missions à forte valeur ajoutée, vous êtes submergée par des tâches chronophages et ennuyantes qui freinent votre motivation et votre productivité.",
        image: pain1,
    },
];



const HomePagePain = () => {
    const [activeIndex, setActiveIndex] = useState(0); // Définit le premier pain point comme actif par défaut

    const handleToggle = (index) => {
        setActiveIndex(index); // Met à jour l'index actif
    };

    return (
        <section className="bg-gray-50 py-16">
            <HomePageContainer className="space-y-12">
                {/* Titre et sous-titre */}
                <div className="text-center">
                    <Typography variant="h3">
                        Vous aussi, vous êtes fatiguée de perdre du temps sur des tâches administratives ?
                    </Typography>
                    <Typography variant="caption3" theme="gray-600" className="mt-4">
                        Rédiger manuellement chaque document, jongler entre des modèles, vérifier chaque détail… Vous méritez mieux.
                    </Typography>
                </div>

                <div className="flex flex-col md:flex-row md:space-x-12">
                    {/* Conteneur fixe pour l'image */}
                    <div
                        className="flex-1 flex items-center justify-center bg-gray-100 rounded-lg shadow-md md:w-full lg:w-2/3"
                        style={{ minHeight: "300px", maxHeight: "400px" }}
                    >
                        {activeIndex !== null ? (
                            <div className="w-full h-full relative rounded-lg overflow-hidden">
                                <Img
                                    src={painPoints[activeIndex].image}
                                    alt={painPoints[activeIndex].title}
                                    className="w-full h-full object-cover transition-transform duration-300 transform hover:scale-105"
                                    loader={<div>Chargement de l'image...</div>} // Optionnel : Loader personnalisé
                                />
                            </div>
                        ) : (
                            <Typography
                                variant="body-lg"
                                theme="gray-500"
                                className="text-center italic"
                            >
                                Sélectionnez un point pour voir l'image associée.
                            </Typography>
                        )}
                    </div>
                    
                    
                    {/* Liste des Pain Points (dropdowns) */}
                    <div className="flex-1 space-y-4">
                        {painPoints.map((point, index) => (
                            <div key={index} className="border-b border-gray-300 pb-4">
                                <Typography
                                    variant="body-lg"
                                    weight="bold"
                                    className="cursor-pointer text-gray-800 hover:text-primary-600"
                                    onClick={() => handleToggle(index)}
                                >
                                    {point.title}
                                </Typography>
                                {activeIndex === index && (
                                    <Typography
                                        variant="body-base"
                                        theme="gray-600"
                                        className="mt-4 transition-all duration-500 ease-in-out"
                                    >
                                        {point.description}
                                    </Typography>
                                )}
                            </div>
                        ))}
                    </div>

                    
                </div>
            </HomePageContainer>
        </section>
    );
};

export default HomePagePain;
