import React from "react";
import { Img } from "react-image";
import Typography from "../Typography";
import Loader from "../Loader";

const TutorialSection = ({ section, gifs, openModal }) => {
  return (
    <div
      id={section.title.toLowerCase().replace(/\s+/g, '-')} // Ajout de l'ID dynamique
      className={`flex flex-col items-center gap-4 md:gap-8 md:flex-row py-8 md:py-12 ${
        section.last ? "" : "border-b border-gray-200"
      }`}
    >
      {/* Texte explicatif */}
      <div className="w-full md:w-2/3 space-y-4">
        <Typography
          variant="h4"
          weight="bold"
          className="mb-2 break-words underline"
        >
          {section.id}. {section.title}
        </Typography>

        {/* Quand utiliser */}
        <Typography variant="caption3">
          <strong>Quand l'utiliser ?</strong> : {section.when}
        </Typography>

        {/* Comment l'écrire */}
        {section.how && (
          <Typography variant="caption3" className="whitespace-pre-line">
            <strong>Comment l'écrire ?</strong> : {section.how}
          </Typography>
        )}

        {/* Exemple */}
        {section.example && (
          <Typography
            variant="caption3"
            weight="bold"
            className="whitespace-pre-line italic mt-2 py-1"
          >
            Exemple :
            <pre className="bg-gray-100 p-3 font-mono text-gray-600 overflow-auto">
              {section.example}
            </pre>
          </Typography>
        )}

        {/* Fonctionnement */}
        <Typography variant="caption3" className="whitespace-pre-line">
          <strong>Fonctionnement :</strong> {section.functionality}
        </Typography>

        {/* Note */}
        {section.note && (
          <div className="p-2 bg-yellow-50 border-l-4 border-yellow-400 text-yellow-700">
            <Typography
              variant="caption4"
              className="text-yellow-800 italic"
            >
              Note : {section.note}
            </Typography>
          </div>
        )}
      </div>

      {/* GIF cliquable */}
      <div className="w-full md:w-1/3 flex flex-col justify-center items-center">
        <div
          className="w-full max-w-[240px] aspect-square bg-gray-100 rounded border border-primary-300 hover:border-primary hover:border-2 flex items-center justify-center overflow-hidden cursor-pointer"
          onClick={() => openModal(gifs[section.gif])}
        >
          <Img
            src={gifs[section.gif]}
            alt={`Illustration pour ${section.title}`}
            className="w-full h-full object-cover"
            loader={
              <div className="flex items-center justify-center h-full bg-gray-200">
                <Loader size="md" color="primary" />
              </div>
            }
            unloader={
              <Typography variant="caption3" theme="gray-600">
                Image non disponible
              </Typography>
            }
          />
        </div>

        {/* Texte sous le GIF */}
        <Typography
          variant="caption4"
          className="mt-2 text-gray-500 italic text-center"
        >
          Cliquez sur l'image pour agrandir
        </Typography>
      </div>
    </div>
  );
};

export default TutorialSection;
