import React, { useState } from 'react';
import { Modal } from 'flowbite-react';
import Loader from '../../Loader';
import UploadDocument from '../../../Home/Modal/ModalUtilities/UploadDocument';
import { uploadAndExtractPlaceholders } from '../../../../utils/apiUtilities';

import TextLink from '../../TextLink';
import Button from '../../Button';
import Typography from '../../Typography';

function ModalAddModel({
  isOpen,
  onClose,
  onUploadSuccess,    // callback unique pour gérer la suite
  userCategories = [], // Liste des catégories disponibles
  isAdmin = false,
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentName, setDocumentName] = useState("");       // Champ nom
  const [selectedCategory, setSelectedCategory] = useState("");  // Sélection catégorie
  const [isLoading, setIsLoading] = useState(false);

  // Callback pour récupérer le fichier sélectionné
  const handleFileChange = (file) => {
    setSelectedFile(file);
    if (file && !documentName) {
      // Si vous voulez pré-remplir le champ "Nom du document"
      // depuis le nom de fichier :
      setDocumentName(file.name.replace(/.docx$/i, ""));
    }
  };

  // Gère le clic sur "Valider/Suivant"
  const handleFileUpload = async () => {
    if (!selectedFile) return;

    setIsLoading(true);
    try {
      // Exemple : on passe un objet metadata au back
      const metadata = {
        name: documentName,
        category: selectedCategory,
      };

      // Appel API pour uploader & analyser
      const response = await uploadAndExtractPlaceholders(selectedFile, metadata, isAdmin);
      console.log("ModalAddModel - response", response);

      // Appelez un callback unique pour indiquer la fin du process
      onUploadSuccess?.(response);

      // Ferme la modal si tout s'est bien passé
      onClose();
    } catch (error) {
      console.error("Erreur lors de l'ajout du document :", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={isOpen}
      onClose={onClose}
      size="xl"
      dismissible={true}
    >
      {/* En-tête de la modal */}
      <Modal.Header>
        <div className="flex flex-col space-y-4">
          <Typography variant="caption1" theme="primary">
            Ajouter un nouveau modèle
          </Typography>

          <Typography 
            variant="body-base"
            theme='gray-400'
            >
            Choississez un modèle depuis votre ordinateur
          </Typography>
        </div>
      </Modal.Header>

      {/* Corps de la modal */}
      <Modal.Body>
        {isLoading ? (
          <div className="flex flex-col items-center">
            <Loader size="lg" color="primary" />
            <p className="text-gray-400 mt-4">
              Chargement du document, veuillez patienter...
            </p>
          </div>
        ) : (
          <>
            {/* Zone de sélection du fichier */}
            <UploadDocument onUpload={handleFileChange} />
            
            {/* Champ "Nom du document" */}
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-600 mb-1">
                Nom du document
              </label>
              <input
                type="text"
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
                placeholder="Ex: Contrat de travail"
                className="border rounded w-full px-3 py-2 focus:outline-none focus:ring-1 focus:ring-primary-300"
              />
            </div>

            {/* Sélecteur de catégorie */}
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-600 mb-1">
                Catégorie
              </label>
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                className="border rounded text-gray-600 w-full px-3 py-2 focus:outline-none focus:ring-1 focus:ring-primary-300"
              >
                <option value="">-- Sélectionner une catégorie --</option>
                {userCategories.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>
            </div>

            {/* Bas de la modal (boutons) */}
            <div className="flex justify-between items-center w-full mt-8">
              <TextLink variant="retour" size="md" onClick={onClose}>
                Annuler
              </TextLink>
              <Button
                variant="primary"
                size="sm"
                onClick={handleFileUpload}
                disabled={!selectedFile}
              >
                {selectedFile ? "Suivant" : "Valider"}
              </Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ModalAddModel;
