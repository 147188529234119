import React from 'react';
import Typography from '../../style/Typography';

const SidePanel = ({ isOpen, onClose, title, children, footer }) => {
    return (
        <div
            className={`fixed right-0 w-1/3 bg-white shadow-lg transform transition-transform ${
                isOpen ? 'translate-x-0' : 'translate-x-full'
            }`}
            style={{
                zIndex: 1000,
                top: '50px', // Décalage depuis le haut
                height: 'calc(100% - 80px)' // Hauteur ajustée en fonction du header
            }}
        >
            {/* Header du SidePanel avec le titre et un bouton de fermeture */}
            <div className="p-4 border-b flex justify-between items-center">
                <Typography
                    variant='body-lg'
                    weight='medium'
                    theme='primary'
                >
                    {title}
                </Typography>
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                    X
                </button>
            </div>

            {/* Contenu du SidePanel */}
            <div className="flex-1 p-4 overflow-y-auto">
                {children}
            </div>

            {/* Footer */}
            {footer && (
                <div 
                    className="w-full px-8 py-4 bg-gray-100"
                    style={{
                        flexShrink: 0,
                    }}
                >
                    {footer}
                </div>
            )}
        </div>
    );
};

export default SidePanel;
