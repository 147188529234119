import React, { useState } from 'react';
import { HomePageContainer } from '../../../components/Containers/HomePageContainer';
import Typography from '../../../components/style/Typography';

const faqs = [
    { 
        question: "Comment fonctionne Bara Doc ?", 
        answer: "Bara Doc vous permet de générer facilement vos documents RH et administratifs, soit à partir de modèles prêts à l'emploi conçus pour vous, soit en utilisant vos propres modèles."
    },
    {
        question: "Que signifie être en phase bêta ?",
        answer: "Être en phase bêta signifie que Bara Doc est encore en cours de développement et d'amélioration. Cela nous permet de recueillir vos retours pour ajuster l'outil à vos besoins réels, tout en vous offrant un accès gratuit et exclusif pour tester ses fonctionnalités."
    },
    { 
        question: "Puis-je utiliser le service gratuitement ?", 
        answer: "Oui, durant la phase de développement, le service est entièrement gratuit. De plus, nous offrirons des accès gratuits aux testeurs ayant contribué à l'amélioration de Bara Doc."
    },
    { 
        question: "Mes données sont-elles sécurisées ?", 
        answer: "Oui, nous prenons la sécurité très au sérieux. Bara Doc fonctionne sans stockage de vos informations personnelles ou documents générés. Tout reste sur votre appareil, supprimant les risques liés au stockage en ligne ou aux serveurs tiers." 
    },
    { 
        question: "Est-ce compatible avec tous mes anciens modèles Word ?", 
        answer: "Oui, vous pouvez utiliser tout vos modèles existants et les adapter selon vos envies. Pour en savoir plus vous pouvez voir notre Guide" 
    },
    {
        question: "Comment vous contacter ?",
        answer: "Si vous rencontrez un bug, avez une demande de fonctionnalité ou souhaitez simplement discuter de vos besoins, vous pouvez nous contacter par email à l'adresse charleston.marcelie12@gmail.com ou par téléphone au 07 45 20 41 74. Nous sommes à votre écoute et ferons de notre mieux pour vous répondre rapidement et efficacement."
    }    
];

const HomePageFAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <section className="py-16 bg-white">
            <HomePageContainer>
                <Typography variant="h3" className="text-center mb-8">Une question ? Voici les réponses aux plus fréquentes.</Typography>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div 
                            key={index} 
                            className="border border-gray-200 rounded-lg p-4 cursor-pointer hover:bg-gray-50"
                            onClick={() => toggleFAQ(index)}
                        >
                            <Typography 
                                variant="lead" 
                                className="mb-2 flex justify-between items-center"
                            >
                                {faq.question}
                                <span className="ml-4">{openIndex === index ? "-" : "+"}</span>
                            </Typography>
                            {openIndex === index && (
                                <Typography 
                                    variant="body-base" 
                                    theme="gray" 
                                    className="transition-all duration-300 ease-in-out mt-2"
                                >
                                    {faq.answer}
                                </Typography>
                            )}
                        </div>
                    ))}
                </div>
            </HomePageContainer>
        </section>
    );
};

export default HomePageFAQ;
