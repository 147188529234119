import React, { useState } from 'react';
import { HowItWorksContainer } from '../../components/Containers/HowItWorksContainer';
import content from '../../assets/jsonfile/howItWorksContent.json';
import Typography from '../../components/style/Typography';
import { Img } from 'react-image';
import Loader from '../../components/style/Loader';
import Button from '../../components/style/Button';
import { useNavigate } from 'react-router-dom';

import tutorial from '../../assets/images/howitworks/tutorial.jpg';

import { IoCloseSharp } from "react-icons/io5";
import { LuPartyPopper } from "react-icons/lu";


const HowItWorks = () => {
    const navigate = useNavigate();
    const gifContext = require.context("../../assets/gifs/howitworks", false, /\.gif$/);

    // Crée un objet associatif qui lie le nom du fichier à son chemin
    const gifs = gifContext.keys().reduce((acc, path) => {
        const fileName = path.replace("./", ""); // Enlève "./" du chemin
        acc[fileName] = gifContext(path);
        return acc;
    }, {});

    // États pour gérer la modale
    const [isOpen, setIsOpen] = useState(false);
    const [selectedGif, setSelectedGif] = useState(null);

    const openModal = (gif) => {
        setSelectedGif(gif);
        setIsOpen(true);
    };

    const closeModal = () => {
        setSelectedGif(null);
        setIsOpen(false);
    };

    return (
        <section className='py-16'>
            <HowItWorksContainer>
                <Typography variant="h2" className="mb-4 text-center">
                    Comment personnaliser vos documents ?
                </Typography>
                <div className="flex items-center justify-center space-x-2 mb-12 text-gray-500">
                    <Typography 
                        variant='body-sm' 
                        theme='gray-600'
                        className=''
                    >
                        Décembre 2024
                    </Typography>

                    <Typography 
                        variant='body-sm' 
                        theme='gray-600'
                        className='bg-secondary-300 rounded-md py-1.5 px-2'
                    >
                        Tutoriel
                    </Typography>
                </div>


                <div className='mb-8'>
                    {/* Introduction */}
                   <Typography variant="body-base" className="mb-4 text-left text-gray-600 leading-relaxed">
                        Ce guide vous permet de personnaliser vos documents rapidement et efficacement.
                        Finis les <strong>copier-coller interminables</strong>, les <strong>doublons</strong> de modèles ou les <strong>erreurs de version</strong> !
                        À partir d'un seul modèle, créez facilement des variantes adaptées à chaque cas spécifique.
                    </Typography>

                    {/* Image */}
                    <div className="flex justify-center h-[200px] md:h-[300px]  rounded-sm mb-8">
                        <Img
                            src={tutorial}
                            alt="Aperçu de la personnalisation"
                            loader={
                                <div className="flex items-center justify-center h-full bg-gray-200">
                                    <Loader size="md" color="primary" />
                                </div>
                            }
                            unloader={
                                <Typography variant="caption3" theme="gray-600">
                                    Image non disponible
                                </Typography>
                            }
                        />
                    </div>

                    <div className="bg-gray-100 border border-gray-200 rounded-lg p-4 mb-8 shadow-sm">
                        <Typography variant="body-lg" className="font-bold text-primary-600 mb-2">
                            Sommaire
                        </Typography>
                        <ul className="list-disc list-inside text-gray-600">
                            {content.map((section) => (
                                <li key={section.id}>
                                    <a 
                                        href={`#${section.title.toLowerCase().replace(/\s+/g, '-')}`}
                                        className="hover:text-primary-500"
                                    >
                                        {section.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>


                    
                </div>
                
        
                {content.map((section, index) => (
                    <div
                        key={section.id}
                        className={`flex flex-col items-center gap-4 md:gap-8 md:flex-row py-8 md:py-12 ${
                            index !== content.length - 1 ? 'border-b border-gray-200' : ''
                        }`}
                    >
                        {/* Texte explicatif */}
                        <div className="w-full md:w-2/3 space-y-4">
                            {/* Titre */}
                            <Typography
                                variant="h4"
                                weight='bold'
                                className="mb-2 break-words underline"
                            >
                                {section.id}.  {section.title}
                            </Typography>
        
                            {/* Quand utiliser */}
                            <Typography variant="caption3">
                                <strong>Quand l'utiliser ?</strong> : {section.when}
                            </Typography>
        
                            {/* Comment l'écrire */}
                            {section.how && (
                                <Typography 
                                    variant="caption3"
                                    className="whitespace-pre-line" 
                                >
                                    <strong>Comment l'écrire ?</strong> : {section.how}
                                </Typography>
                            )}

                            {/* Exemple */}
                            {section.example && (
                                <Typography variant="caption3" weight='bold' className="whitespace-pre-line italic mt-2 py-1">
                                    Exemple :
                                    <pre className="bg-gray-100 p-3 font-mono text-gray-600 overflow-auto">
                                        {section.example}
                                    </pre>
                                </Typography>
                            )}
        
                            {/* Fonctionnement */}
                            <Typography variant="caption3" className="whitespace-pre-line">
                                <strong>Fonctionnement :</strong> {section.functionality}
                            </Typography>
        
                            {/* Note */}
                            {section.note && (
                                <div className="p-2 bg-yellow-50 border-l-4 border-yellow-400 text-yellow-700">
                                    <Typography
                                        variant="caption4"
                                        className="text-yellow-800 italic"
                                    >
                                        Note : {section.note}
                                    </Typography>
                                </div>
                            )}
        
                            
                        </div>
        
                        {/* GIF cliquable */}
                        <div className="w-full md:w-1/3 flex flex-col justify-center items-center">
                            <div 
                                className="w-full max-w-[240px] aspect-square bg-gray-100 rounded border border-primary-300 hover:border-primary hover:border-2 flex items-center justify-center overflow-hidden cursor-pointer"
                                onClick={() => openModal(gifs[section.gif])}
                            >
                            <Img
                                src={gifs[section.gif]} // Utilise l'objet dynamique pour trouver le chemin
                                alt={`Illustration pour ${section.title}`}
                                className="w-full h-full object-cover"
                                loader={
                                    <div className="flex items-center justify-center h-full bg-gray-200">
                                        <Loader size="md" color="primary" />
                                    </div>
                                }
                                unloader={
                                    <Typography variant="caption3" theme="gray-600">
                                        Image non disponible
                                    </Typography>
                                }
                            />
                            </div>

                            {/* Texte sous le GIF */}
                            <Typography
                                variant="caption4"
                                className="mt-2 text-gray-500 italic text-center"
                            >
                                Cliquez sur l'image pour agrandir
                            </Typography>
                        </div>
                    </div>
                ))}
                    
                {/* Encouragement à l'interaction */}
                <Typography variant="caption4" className="flex text-gray-500 justify-center italic mb-6">
                    D'autres options viendront enrichir l'outil prochainement. Partagez vos idées et retours pour qu'il réponde encore mieux à vos besoins !
                </Typography>

                {/* Bouton d'action */}
                <div className="flex justify-center items-center mt-6">
                        <Button 
                            variant="primary" 
                            size="sm" 
                            icon={LuPartyPopper} 
                            iconPosition='right'
                            className="transform hover:scale-105 transition-transform duration-300"
                            onClick={() => navigate('/document-manager')}
                            >
                            
                            Essayez maintenant
                        </Button>
                    </div>

                {/* Modale pour afficher le GIF en grand */}
                {isOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
                        <div className="relative border border-primary rounded-lg w-full max-w-screen-md sm:max-w-3xl lg:max-w-4xl bg-white rounded-lg overflow-hidden">
                            {/* Bouton personnalisé pour fermer */}
                            <div className="absolute top-2 right-2 z-10">
                                <Button
                                    variant="ico"            // Bouton icône
                                    icon={IoCloseSharp}     // Icône de fermeture
                                    iconTheme="accent"         // Thème gris pour le style
                                    iconSize={28}            // Taille de l'icône
                                    size="md"                // Taille du bouton
                                    onClick={closeModal}     // Fonction pour fermer la modale
                                    className="shadow-lg hover:opacity-80" // Style optionnel
                                />
                            </div>

                            {/* GIF agrandi */}
                            <div className="flex items-center justify-center">
                                <Img
                                    src={selectedGif}
                                    alt="Aperçu du GIF"
                                    className="w-full h-auto rounded-lg"
                                />
                            </div>
                        </div>
                    </div>
                )}
                
            </HowItWorksContainer>
        </section>
    );
    
};

export default HowItWorks;
