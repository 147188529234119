import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import 'swiper/css/pagination';
import Typography from "../style/Typography";

const BenefitCarousel = ({ benefits }) => {
    return (
        <div className="relative w-full px-4 sm:px-8 md:px-16 pb-12">

            <Swiper
                modules={[Pagination, Navigation, Autoplay]}
                spaceBetween={20}
                slidesPerView={1}
                loop={true}
                navigation={true}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                pagination={{ clickable: true }} // Pagination activée et cliquable
                breakpoints={{
                    480: { slidesPerView: 1 }, // Mobile : 1 slide visible
                    768: { slidesPerView: 2 }, // Tablette : 2 slides visibles
                    1024: { slidesPerView: 3 }, // Desktop : 3 slides visibles
                }}
                className="overflow-visible" // Permet à la pagination d'être visible
            >
                {benefits.map((benefit, index) => (
                    <SwiperSlide key={index}>

                        <div className="flex flex-col mx-auto space-y-4 max-w-[260px]">
                            {/* Image carrée et responsive */}
                            <div className="w-full bg-white rounded  shadow-md hover:shadow-lg ">
                                <img
                                    src={benefit.image}
                                    alt={benefit.title}
                                    className="w-full h-full object-cover"
                                />
                            </div>

                            {/* Contenu texte */}
                            <div className="p-4 text-center text-center">
                                <Typography 
                                    variant="caption1" 
                                    weight="bold" 
                                    className="text-gray-800 mb-2"
                                >
                                    {benefit.title}
                                </Typography>
                                <Typography 
                                    variant="caption3" 
                                    theme="gray-600"
                                >
                                    {benefit.description}
                                </Typography>
                            </div>
                        </div>
                    </SwiperSlide>
                
                ))}
            </Swiper>
        </div>
        
    );
};

export default BenefitCarousel;
