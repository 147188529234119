import React from 'react';

const StickyWrapper = ({ children, top = 20 }) => {
    return (
        <div
            className={`sticky top-${top} h-full`}
            style={{ top: `${top}px` }} // Fallback pour une valeur de `top` dynamique
        >
            {children}
        </div>
    );
};

export default StickyWrapper;
