import React from 'react';
import Button from '../../components/style/Button';
import Typography from '../../components/style/Typography';
import { GlobalContainer } from '../../components/Containers/GlobalContainer';
import { useNavigate } from 'react-router-dom';

// Données pour les éléments de la liste
const features = [
    {
        title: "Une bibliothèque prête à l'emploi",
        description: "Des dizaines de modèles pensés pour vos besoins RH, que vous pouvez adapter en un clic."
    },
    {
        title: "Une personnalisation sans prise de tête",
        description: "Une interface simple, conçue pour s’adapter à vos spécificités et vous faire gagner un temps précieux."
    },
    {
        title: "Un système d’options intelligent",
        description: "Dites adieu aux répétitions. Créez des documents qui s’ajustent automatiquement à chaque collaborateur."
    }
];

// Données pour les bénéfices
const benefits = [
    {
        title: "Gagnez du temps",
        description: "Automatisez la création de vos documents et concentrez-vous sur ce qui compte vraiment : l’humain."
    },
    {
        title: "Réduisez les erreurs",
        description: "Des modèles standardisés, testés par des pros, pour une diffusion fiable et sans stress."
    },
    {
        title: "Retrouvez l'équilibre",
        description: "Libérez-vous des tâches chronophages et priorisez votre bien-être, au bureau comme à la maison."
    }
];

function LearnMore() {
    const navigate = useNavigate();

    return (
        <section className="bg-gray-50 py-16">
            <GlobalContainer className="flex flex-col space-y-12">
                
                {/* Titre principal */}
                <div className="text-center">
                    <Typography variant="h1" weight="bold" className="text-primary-600">
                        En savoir plus sur Bara Doc
                    </Typography>
                    <Typography variant="body-lg" theme="gray-700" className="mt-4">
                        Simplifiez la rédaction de vos documents RH avec un outil pensé par et pour les professionnels des Ressources Humaines.
                    </Typography>
                </div>

                {/* Introduction / Notre raison d’être */}
                <div className="bg-white shadow-md rounded-lg p-8">
                    <Typography variant="h2" weight="bold" className="text-primary-600 mb-4">
                        Notre raison d’être
                    </Typography>
                    <div className="space-y-4">
                        <Typography variant="body-base" theme="gray-600" className="leading-relaxed">
                            Un jour, après avoir passé <strong>encore</strong> une énième journée à reformater manuellement des documents RH, 
                            je me suis dit : “Cette corvée administrative ne devrait pas me voler autant de temps et d’énergie.”
                        </Typography>
                        <Typography variant="body-base" theme="gray-600" className="leading-relaxed">
                            C’est dans cet élan qu’est né Bara Doc : <strong>transformer une tâche chronophage en un processus fluide, rapide et efficace.</strong>
                        </Typography>
                        <Typography variant="body-base" theme="gray-600" className="leading-relaxed">
                            Notre promesse ? Offrir aux RH un logiciel qui comprend réellement leurs défis du quotidien. 
                            <strong>Une solution conçue par des professionnels RH, pour des professionnels RH.</strong>
                        </Typography>
                    </div>
                </div>

                {/* Bénéfices principaux */}
                <div className="bg-white shadow-md rounded-lg p-8">
                    <Typography variant="h2" weight="bold" className="text-primary-600 mb-4">
                        Comment Bara Doc vous facilite la vie
                    </Typography>
                    <div className="space-y-8">
                        {benefits.map((benefit, index) => (
                            <div key={index} className="text-center">
                                <Typography variant="body-lg" weight="bold" className="text-gray-800">
                                    {benefit.title}
                                </Typography>
                                <Typography variant="body-base" theme="gray-600" className="mt-2">
                                    {benefit.description}
                                </Typography>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Ce que propose Bara Doc */}
                <div className="bg-white shadow-md rounded-lg p-8">
                    <Typography variant="h2" weight="bold" className="text-primary-600 mb-4">
                        Concrètement, qu’est-ce que Bara Doc ?
                    </Typography>
                    <ul className="space-y-6">
                        {features.map((feature, index) => (
                            <li key={index} className="text-center">
                                <Typography variant="body-lg" weight="bold" className="text-gray-800">
                                    {feature.title}
                                </Typography>
                                <Typography variant="body-base" theme="gray-600" className="mt-2">
                                    {feature.description}
                                </Typography>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Qui sommes-nous */}
                <div className="bg-white shadow-md rounded-lg p-8">
                    <Typography variant="h2" weight="bold" className="text-primary-600 mb-4">
                        À propos de nous
                    </Typography>
                    <Typography variant="body-base" theme="gray-600" className="leading-relaxed">
                        Bara Doc, c’est avant tout une aventure familiale. <br />
                        Je suis un freelance RH et développeur web autodidacte, et ma femme est 
                        à la fois <strong>RH et comptable</strong>. Nous avons uni nos forces pour concevoir 
                        l’outil qui nous manquait : celui qui répondra enfin aux besoins réels des professionnels RH. 
                        Nous connaissons vos enjeux, parce que nous sommes passés par là nous-mêmes.
                    </Typography>
                    <Typography variant="body-base" theme="gray-600" className="leading-relaxed mt-4">
                        Bara Doc est le fruit de notre expérience commune. 
                        Chaque fonctionnalité est pensée pour vous <strong>faire gagner du temps</strong>, 
                        <strong> minimiser les erreurs</strong> et <strong>alléger votre quotidien</strong>. 
                        Ensemble, nous souhaitons bâtir une communauté de RH plus sereins et plus efficaces.
                    </Typography>
                </div>

                {/* Conclusion et CTA */}
                <div className="bg-primary-100 text-primary-800 p-8 rounded-lg text-center">
                    <Typography variant="h2" weight="bold" className="mb-4">
                        Prêt(e) à simplifier vos processus RH ?
                    </Typography>
                    <Typography variant="body-lg" className="leading-relaxed">
                        Nous développons en ce moment la version BETA de Bara Doc. Rejoignez l’aventure, 
                        testez nos fonctionnalités et aidez-nous à créer <strong>la</strong> solution RH de demain !
                    </Typography>
                    <div className="flex justify-center mt-6">
                        <Button 
                            variant="primary" 
                            size="md"
                            className="transform hover:scale-105 transition-transform duration-300"
                            onClick={() => navigate('/document-manager')}
                        >
                            Créer mon premier document
                        </Button>
                    </div>
                </div>
            </GlobalContainer>
        </section>
    );
}

export default LearnMore;
