export const deleteDocument = async (documentId) => {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_URL}/documents/${documentId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,  // IMPORTANT
      }
    });
  
    if (!response.ok) {
      throw new Error('Erreur lors de la suppression du document.');
    }
  
    return await response.json();
  };
  



// Admin
export const fetchPublicDocuments = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/public-documents`);
    if (!response.ok) {
        throw new Error('Erreur lors de la récupération des documents publics.');
    }
    const data = await response.json(); 
    return data;
};

// User
export const fetchUserDocuments = async () => {
    const token = localStorage.getItem('access_token'); 
    const response = await fetch(`${process.env.REACT_APP_API_URL}/user-documents/`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });

    if (!response.ok) {
        throw new Error('Erreur lors de la récupération des documents de l\'utilisateur.');
    }

    const documents = await response.json();

    console.log("privatedocument fetchuser :", documents)

    // Filtrer ou trier les documents si nécessaire
    const privateDocuments = documents.filter(doc => doc.source === "private");
    const publicDocuments = documents.filter(doc => doc.source === "public");

    console.log("Documents privés fetch:", privateDocuments);
    console.log("Documents publics fetch :", publicDocuments);

    return { privateDocuments, publicDocuments };
};



export const updateDocument = async (documentId, updatedData) => {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_URL}/documents/${documentId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // IMPORTANT
        },
        body: JSON.stringify(updatedData),
    });
  
    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Erreur lors de la mise à jour du document');
    }
  
    return await response.json();
  };
  

export const getDocument = async (documentId) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/documents/${documentId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Erreur lors de la récupération du document');
    }

    return await response.json();
};
