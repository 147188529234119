import React from 'react';
import { Img } from "react-image";
import Typography from '../../../components/style/Typography';
import Button from '../../../components/style/Button';
import { HomePageContainer } from '../../../components/Containers/HomePageContainer';
import HeroImage from '../../../assets/homepage/hero-image1.jpg';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/style/Loader';

const HomePageHero = () => {
    const navigate = useNavigate();

    return (
        <HomePageContainer className="relative pt-12 pb-32 flex flex-col items-center justify-between rounded-2xl bg-gradient-to-b from-primary-200 to-white">
            <div className="flex flex-col md:flex-row items-center justify-between w-full max-w-7xl px-2 sm:px-4 lg:px-6 space-y-8 md:space-y-0">
                

                {/* Texte principal */}
                <div className="space-y-8 w-[90%] sm:w-[70%] md:w-[65%] md:flex-grow z-10 flex flex-col text-center md:text-left">
                    <Typography 
                        variant="h3" 
                        className="text-black drop-shadow-md animate-fadeIn text-3xl sm:text-5xl md:text-6xl lg:text-7xl"
                        >
                        <span className="block ">
                            Générez <span className="bg-gradient-to-r from-primary-600 to-primary-400 text-transparent bg-clip-text">sereinement</span>
                        </span>
                        <span className="block">
                            vos documents RH 
                        </span>
                        <span className="block">
                            <span className="bg-gradient-to-r from-primary-600 to-primary-400 text-transparent bg-clip-text">en quelques clics</span>
                        </span>
                        <span className="block text-3xl sm:text-4xl md:text-5xl font-light text-gray-600">(Bêta-test)</span>
                    </Typography>

                    <Typography 
                        variant="body-base" 
                        theme="gray-600"
                    >
                        "Finis les copier-coller et les erreurs de version."<br/>
                        Grâce à la détection automatique de vos mots-clés, <br/> 
                        personnalisez vos modèles et gagnez du temps.
                    </Typography>

                    <div className="flex flex-col md:flex-row md:space-x-6 space-y-4 md:space-y-0 justify-center md:justify-start">
                    <Button 
                            variant="secondary"
                            size="md"
                            onClick={() => navigate('/learn-more')}
                        >
                            En savoir plus
                        </Button>

                        <Button 
                            variant="primary" 
                            size="md" 
                            onClick={() => navigate('/document-manager')}
                        >
                            Essayez gratuitement
                        </Button>
                        
                    </div>
                </div>

                {/* Image */}
                <div className="hidden sm:block relative w-[30%] h-auto rounded-xl overflow-hidden shadow-md bg-green-500">
                    <Img
                        src={HeroImage}
                        alt="Illustration"
                        className="object-cover w-full h-full"
                        loader={<div className="flex items-center justify-center h-full bg-gray-200"><Loader size="md" color="primary" /></div>}
                        unloader={<div>Image introuvable</div>}
                    />
                </div>
            </div>

            {/* Phrase d'accroche supplémentaire */}
            <div className="mt-10 px-4 text-center">
                <Typography 
                    variant="caption2" 
                    className="text-gray-600 italic"
                >
                    "Créer vos documents n'aura jamais été aussi simple."
                </Typography>
            </div>
        </HomePageContainer>
    );
};

export default HomePageHero;
