import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './styles/global.css';  // Assurez-vous que cet import est correct
import Home from './pages/Home'
import HowItWorks from './pages/HowItWorks';
import FeaturesToAdd from './pages/FeaturesToAdd';
import DesignSystem from './pages/Design-system';
import Workspace from './pages/Workspace';
import Header from './components/Header';
import DocumentManagerPage from './pages/DocumentManagerPage';
import LoginModal from './components/Login/LoginModal';
import LearnMore from './pages/LearnMore';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import TermsAndConditions from './pages/Legal/TermsAndConditions';
import LegalNotices from './pages/Legal/LegalNotices';

import { UserProvider } from './context/User/UserContext';
import { ModalProvider } from './context/Login/ModalContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ProtectedRoute } from './components/ProtectedRoutes/ProtectedRoute'
 
import { withRoleProtection } from './utils/Utilities';

// Workspace protégé
const ProtectedWorkspace = withRoleProtection(Workspace, ['GUEST', 'USER', 'ADMIN', 'SUPERADMIN']); // Liste des rôles autorisés


ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <UserProvider>
        <ModalProvider>
          <Router>
          <div className="flex flex-col h-screen">
              <Header />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/how-it-works" element={<HowItWorks />} />
                  <Route path="/login" element={<LoginModal />} />
                  <Route path="/features-to-add" element={<FeaturesToAdd />} />
                  <Route path="/design-system" element={<DesignSystem />} />
                  <Route path="/document-manager" element={<DocumentManagerPage />} />
                  <Route
                    path="/workspace"
                    element={
                      <ProtectedRoute>
                        <ProtectedWorkspace />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/learn-more" element={<LearnMore />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                  <Route path="/legal-notices" element={<LegalNotices />} />
                </Routes>
                <LoginModal />
            </div>
          </Router>
        </ModalProvider>
      </UserProvider>
    </GoogleOAuthProvider>   
  </React.StrictMode>,
  document.getElementById('root')
)