import React, { useEffect, useState } from 'react';
import CustomTable from '../../../style/Tables/CustomTable';
import { fetchUserDocuments } from '../../../../utils/ApiDocsUtilities';

import Typography from '../../../style/Typography';
import Button from '../../../style/Button';
import Loader from '../../../style/Loader';
import { FaFolderOpen } from "react-icons/fa";
import { IoAddOutline } from "react-icons/io5";

import ModalAddModel from '../../../style/Modals/ModalAddModel';
import SidePanel from '../../../SidePanelComponents/SidePanel';
import { deleteDocument, updateDocument, getDocument} from '../../../../utils/ApiDocsUtilities';
import EditUserDocsForm from '../../../SidePanelComponents/EditUserDocsForm';

function UsersModelsContainer() {
    const [privateDocuments, setPrivateDocuments] = useState([]);
    const [publicDocuments, setPublicDocuments] = useState([]);
    
    const [isFetchLoading, setIsFetchLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Gestion de l'édition
    const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
    const [modelToEdit, setModelToEdit] = useState(null);
    const [error, setError] = useState(null);

    // Fonction pour charger les documents de l'utilisateur
    const fetchDocuments = async () => {
        try {
            setIsFetchLoading(true);

            // Récupérer les documents (privés et publics)
            const { privateDocuments: privateDocs, publicDocuments: publicDocs } = await fetchUserDocuments();

            setPrivateDocuments(privateDocs);
            setPublicDocuments(publicDocs);

        } catch (error) {
            console.error('Erreur lors de la récupération des documents:', error);
        } finally {
            setIsFetchLoading(false);
        }
    };

    useEffect(() => {
        fetchDocuments();
    }, []);

    // Si c'est en cours de chargement, on affiche le loader au centre
    if (isFetchLoading) {
        return (
        <div className="flex flex-col items-center justify-center h-[60vh]">
            <Loader size="md" color="primary" />
            <p className="text-gray-500 mt-2">Chargement des documents...</p>
        </div>
        );
    }

    // Définir les colonnes
    const columns = [
        { key: "Nom", title: "Nom", ratio: 2 }, // Utilise "Nom"
        { key: "Catégorie", title: "Catégorie", ratio: 1 }, // Utilise "Catégorie"
        { key: "Date de création", title: "Date de création", ratio: 1 }, // Utilise "Date de création"
    ];
    
    
    
    // Formater les données
    // const formattedData = [...listDocument.privateDocuments, ...listDocument.publicDocuments].map((doc) => ({
    const formattedPrivateData = privateDocuments.map((doc) => ({
        id: doc.id,
        Nom: doc.name, // Correspond à "Nom"
        Catégorie: doc.category, // Correspond à "Catégorie"
        "Date de création": new Date(doc.created_at).toLocaleDateString("fr-FR"), // Correspond à "Date de création"
    }));
    //console.log("privatedocument :", privateDocuments);
    //console.log("PublicDocuments", publicDocuments);
    //console.log("FormattedPrivateData :", formattedPrivateData);

    
    
    const handleGenerate = (model) => {
        console.log("Générer le document :", model);
        // Ajoutez ici la logique pour générer un document
    };

    // Fonction d'édition
    const handleEdit = async (model) => {
        const fullDocument = await getDocument(model.id);
        console.log("Click dans HandleEdit + fullDocument", fullDocument);
        setModelToEdit(fullDocument); // Passe directement les données de la ligne
        setIsEditPanelOpen(true); // Ouvre le panneau d'édition
    };
    
    const collectUpdatedModel = (updatedData) => {
        setModelToEdit((prev) => ({
            ...prev,
            ...updatedData,
        }));
    };    

    const handleSaveEdit = async () => {
        if (!modelToEdit || !modelToEdit.id) {
            console.error("ID du document non défini ou données invalides :", modelToEdit);
            return;
        }
    
        console.log("Données préparées pour la sauvegarde :", modelToEdit);
    
        try {
            // Appel à l'API pour mettre à jour le document
            const response = await updateDocument(modelToEdit.id, modelToEdit);
            console.log("Réponse du backend :", response);
    
            // Mettre à jour la liste locale
            setPrivateDocuments((prevDocuments) =>
                prevDocuments.map((doc) =>
                    doc.id === modelToEdit.id ? { ...doc, ...modelToEdit } : doc
                )
            );
    
            // Fermer le panneau d'édition
            setIsEditPanelOpen(false);
        } catch (error) {
            console.error("Erreur lors de la mise à jour :", error);
            setError("Erreur lors de la mise à jour du document.");
        }
    };
    
    

    const handleCancelEdit = () => {
        console.log("Click dans HandleCancelEdit");
        setIsEditPanelOpen(false);
    };

    // Fonction de suppression
    const handleDelete = async (model) => {
    try {
        await deleteDocument(model.id);
        setPrivateDocuments((prev) => prev.filter((doc) => doc.id !== model.id));
        console.log("Document supprimé avec succès :", model.name);
    } catch (error) {
        console.error("Erreur lors de la suppression :", error);
    }
    };

    // Fonction de téléchargement
    const handleDownload = (model) => {
        console.log("Téléchargement du document :", model);
    };


    // Gestion des actions via une colonne fixe
     // Gestion des actions
    const handleAction = (action, row) => {
        switch (action) {
        case "configurer":
            handleEdit(row);
            break;
        case "supprimer":
            handleDelete(row);
            break;
        case "générer":
            handleGenerate(row);
            break;
        case "télécharger":
            handleDownload(row);
            break;
        default:
            console.log("Action inconnue pour:", row);
        }
    };

    // Ouvre la modal d'ajout
    const handleOpenAddModel = () => {
        setIsModalOpen(true);
    };

    // Callback lorsque le document a été uploadé avec succès
    const handleUploadSuccess = async (response) => {
        console.log('Document ajouté avec succès:', response);
        // Ferme la modal
        setIsModalOpen(false);
        // Recharge la liste des documents
        await fetchDocuments();
    };

    return (
        <section className="flex flex-col px-8 space-y-8">
            {/* Barre de titre + bouton */}
            <div className="border-b py-6 flex items-center justify-between">
                {/* Titre + icône dans un cercle */}
                <div className="flex items-center space-x-3">
                    {/* Cercle contenant l'icône */}
                    <div className="w-12 h-12 rounded-full bg-primary flex items-center justify-center">
                        <FaFolderOpen className="text-white" />
                    </div>

                    <Typography variant="body-base" weight="bold" className="text-gray-800">
                        Vos modèles de documents (<span className='text-primary'>{formattedPrivateData.length}</span>)
                    </Typography>
                </div>

                {/* Bouton "Ajouter" avec l'icône IoAddOutline */}
                <Button
                    variant="primary"
                    size="sm"
                    icon={IoAddOutline}      // <-- On passe l'icône
                    iconPosition="left"      // <-- Icône à gauche
                    onClick={handleOpenAddModel}
                >
                    Ajouter un modèle
                </Button>
            </div>



            {/* Tableau */}
            <div className="flex w-full max-h-[70vh] rounded-lg border shadow-md">
                <CustomTable 
                    columns={columns} 
                    data={formattedPrivateData} 
                    onAction={handleAction} 
                    isPublic={false}
                />
            </div>

            {/* Modal d'ajout de document */}
            <ModalAddModel
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onUploadSuccess={handleUploadSuccess}
            />

            {/* Panneau d'édition */}
            <SidePanel 
                isOpen={isEditPanelOpen} 
                onClose={handleCancelEdit} 
                title="Configuration du modèle"
                footer={
                    <div className="flex w-full justify-between">
                        {/* Bouton Annuler */}
                        <Button variant="outline" size="sm" onClick={handleCancelEdit} className="rounded-md">
                            Annuler
                        </Button>

                        {/* Bouton Enregistrer */}
                        <Button variant="primary" size="sm" onClick={handleSaveEdit} type="submit" className="rounded-md">
                            Enregistrer
                        </Button>
                    </div>
                }
            >
                {modelToEdit && (
                <EditUserDocsForm
                    model={modelToEdit}
                    onSave={collectUpdatedModel}
                    onCancel={handleCancelEdit}
                />
                )}
            </SidePanel>
        </section>
    );
}

export default UsersModelsContainer;
