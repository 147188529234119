import React, { useState, useRef, useEffect } from 'react';
import { FiMoreVertical } from 'react-icons/fi';

function ActionsMenu({ onConfigure, onDownload, onDelete, onGenerate }) {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  // Fermer le menu quand on clique en dehors
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <div className="relative inline-block" ref={menuRef}>
      {/* Bouton principal (icône trois points) */}
      <button
        onClick={toggleMenu}
        className="p-2 rounded-full  text-blue-500 hover:bg-gray-100"
      >
        <FiMoreVertical size={20} />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-36 bg-white border border-gray-200 rounded shadow-lg z-50">
          {/* Générer */}
          <button
            onClick={() => {
              onGenerate?.();
              setIsOpen(false);
            }}
            className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
          >
            Générer
          </button>
          {/* Éditer */}
          <button
            onClick={() => {
              onConfigure?.();
              setIsOpen(false);
            }}
            className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
          >
            Éditer
          </button>
          {/* Télécharger */}
          <button
            onClick={() => {
              onDownload?.();
              setIsOpen(false);
            }}
            className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
          >
            Télécharger
          </button>
          {/* Supprimer */}
          <button
            onClick={() => {
              onDelete?.();
              setIsOpen(false);
            }}
            className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 text-red-600"
          >
            Supprimer
          </button>
        </div>
      )}
    </div>
  );
}

export default ActionsMenu;
