import React from "react";
import Button from "../../Button";
import Typography from "../../Typography";
import ActionsMenu from "../../ActionsMenu";

import { IoDocumentText, IoOptionsOutline } from "react-icons/io5";
import { SlOptionsVertical } from "react-icons/sl";



const CustomTable = ({ columns, data, onAction, isPublic = false }) => {
    return (
        <div className="w-full rounded-lg border shadow-sm">
          <div>
            {/* Conteneur pour le tableau */}
            <div className="max-h-[60vh]">
              <table className="w-full table-fixed border-collapse">
                {/* En-tête */}
                <thead className="bg-gray-100 sticky top-0 z-10">
                  <tr>
                    {columns.map((col) => (
                      <th
                        key={col.key}
                        className={`px-8 py-6 text-left text-sm font-medium text-gray-600 border-b bg-gray-100`}
                        style={{
                          width: col.ratio ? `${col.ratio * 20}%` : "auto", // Applique un ratio basé sur 5 parties
                        }}
                      >
                        <Typography variant="caption3" weight="bold">
                          {col.title}
                        </Typography>
                      </th>
                    ))}
                    <th
                      className="px-8 py-6 text-center text-sm font-medium text-gray-600 border-b bg-gray-100"
                      style={{ width: "20%" }}
                    >
                      <Typography variant="caption3" weight="bold">
                        Actions
                      </Typography>
                    </th>
                  </tr>
                </thead>
    
                {/* Corps */}
                <tbody>
                  {data.map((row, rowIndex) => (
                    <tr key={rowIndex} className="bg-white hover:bg-gray-50">
                      {columns.map((col) => (
                        <td
                          key={col.key}
                          className="px-8 py-4 text-sm text-gray-700 border-b whitespace-nowrap text-ellipsis"
                          style={{
                            width: col.ratio ? `${col.ratio * 20}%` : "auto",
                          }}
                        >
                          {col.key === "Nom" ? (
                            <div className="flex items-center space-x-2">
                              <IoDocumentText className="text-blue-600 w-4 h-4" />
                              <Typography variant="caption4">
                                {row[col.key]}
                              </Typography>
                            </div>
                          ) : (
                            <Typography variant="caption4">
                              {row[col.key]}
                            </Typography>
                          )}
                        </td>
                      ))}
                      {/* Colonne Actions */}
                      <td
                        className="px-6 py-4 text-sm text-gray-700 border-b text-center relative overflow-visible"
                        style={{ width: "20%" }}
                      >
                        {isPublic ? (
                          // Cas des documents publics
                          <button
                            onClick={() => onAction("générer", row)}
                            className="p-2 rounded-full hover:bg-gray-100"
                          >
                            Générer
                          </button>
                        ) : (
                          // Cas des documents de l'utilisateur
                          <ActionsMenu
                            onGenerate={() => onAction("générer", row)}
                            onConfigure={() => onAction("configurer", row)}
                            onDownload={() => onAction("télécharger", row)}
                            onDelete={() => onAction("supprimer", row)}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
};

export default CustomTable;
