import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchCurrentUser } from "../../../utils/Api/ApiUsersUtilities"; // Importer la nouvelle fonction

const UserContext = createContext();

export function UserProvider({ children }) {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userData = await fetchCurrentUser();
                setUser(userData);
                console.log("Dans le FetchUser")
            } catch (error) {
                console.error("Erreur lors de la récupération des informations utilisateur :", error);
                setUser(null); // Non authentifié
            } finally {
                setIsLoading(false); // Le chargement est terminé
            }
        };

        fetchUser();
    }, []);

    const login = (userData) => {
        //console.log('Mise à jour du contexte utilisateur avec :', userData);
        setUser(userData);
        // Stocker l'access_token si nécessaire
        if (userData.access_token) {
            localStorage.setItem('access_token', userData.access_token);
        }
    };
    

    const logout = () => {
        setUser(null);
        localStorage.removeItem('access_token'); // Supprime le token du stockage local
    };

    return (
        <UserContext.Provider value={{ user, login, logout, isLoading }}>
            {children}
        </UserContext.Provider>
    );
}

export function useUser() {
    return useContext(UserContext);
}
