import React, { useState } from 'react';
import { FiFilePlus } from 'react-icons/fi'; // Exemple d'icône
import Typography from '../../../../style/Typography';

function UploadDocument({ onUpload }) {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            console.log("File selected:", file);
            onUpload(file); // On upload simplement le fichier ici, sans changer d'étape
        }
    };
    

    return (
        <div className="flex items-center justify-center p-8
        border border-dashed rounded text-center cursor-pointer hover:bg-gray-50
        border-gray-500">

             {/* Label pour simuler le clic sur l'input file */}
            <label
                htmlFor="file-upload"
                className="flex flex-col items-center cursor-pointer"
            >
                <FiFilePlus className="text-4xl text-gray-500 mb-4" /> {/* Icône pour l'upload */}

                {/* Message au centre */}
                {selectedFile ? (
                    <div>
                        <Typography
                            variant="body-base"
                            theme="gray-600"
                            className="mb-1"
                        >
                            Fichier sélectionné : <strong>{selectedFile.name}</strong>
                        </Typography>
                    </div>
                    
                    
                    ) : (
                        <div>
                            <Typography
                                variant="body-base"
                                theme="gray-600"
                                weight='bold'
                                className="mb-1"
                            >
                                Sélectionnez un document Word pour commencer
                            </Typography>
                            <Typography
                                variant="caption4"
                                theme="gray-400"
                                className="mb-4"
                            >
                                Fichiers acceptés : Doc, Docx
                            </Typography>
                        </div>
                    
                    )}

                    {/* Bouton “Ajouter / Changer de document” */}
                    <span className="bg-secondary-600 text-white px-4 py-2 rounded hover:bg-secondary-400 shadow-md">
                    {selectedFile ? 'Changer de document' : 'Ajouter un document'}
                    </span>
                </label>

            {/* Input file caché */}
            <input 
                id="file-upload" 
                type="file" 
                accept=".doc,.docx" 
                onChange={handleFileChange} 
                className="hidden" // Masquer l'input file
            />
        </div>
    );
}


export default UploadDocument;
