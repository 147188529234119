import React, { useEffect, useRef, useState } from 'react';
import { HomePageContainer } from '../../../components/Containers/HomePageContainer';
import { useNavigate } from 'react-router-dom';
import Typography from '../../../components/style/Typography';
import Button from '../../../components/style/Button';
import BenefitCarousel from '../../../components/BenefitCarousel';

import benefit1 from '../../../assets/svg/benefits/benefit1.jpeg';
import benefit2 from '../../../assets/svg/benefits/benefit2.jpeg';
// import benefit3 from '../../../assets/svg/benefits/benefit3.png';
import benefit4 from '../../../assets/svg/benefits/benefit4.jpg';
import benefit5 from '../../../assets/svg/benefits/benefit5.jpeg';
// import benefit6 from '../../../assets/svg/benefits/benefit3.png';
// import benefit7 from '../../../assets/svg/benefits/benefit3.png';
import benefit8 from '../../../assets/svg/benefits/benefit8.jpeg';

const benefits = [
    {
        title: "Modèles prêts à l'emploi",
        description: "Créez vos documents en quelques minutes, sans copier-coller ni perte de temps.",
        image: benefit1,
    },
    {
        title: "Interface simple et intuitive",
        description: "Sélectionnez, personnalisez et téléchargez vos documents en quelques clics seulement.",
        image: benefit2,
    },
    {
        title: "Modèles entièrement personnalisables",
        description: "Ajoutez ou supprimez des sections en toute simplicité pour répondre à vos besoins spécifiques.",
        image: benefit1,
    },
    {
        title: "Gagnez du temps",
        description: "Centralisez vos modèles et générez vos documents en quelques minutes, même pour plusieurs clients.",
        image: benefit4, // Image pour le gain de temps
    },
    {
        title: "Organisez vos modèles par entreprise",
        description: "Classez vos modèles et documents par client pour un accès rapide et structuré.",
        image: benefit5, // Image pour l'organisation
    },
    {
        title: "Documents faciles à adapter",
        description: "Modifiez, adaptez et personnalisez vos modèles en un clin d'œil pour répondre aux exigences spécifiques de vos clients.",
        image: benefit1, // Image pour la facilité de personnalisation
    },
    {
        title: "Réduisez les erreurs",
        description: "Automatisez le remplissage des champs pour des documents fiables et cohérents.",
        image: benefit1, // Image pour éviter les erreurs
    },
    {
        title: "Flexibilité et efficacité garanties",
        description: "Accédez à vos modèles où que vous soyez et générez des documents même en déplacement.",
        image: benefit8, // Image pour la flexibilité
    }
];


const HomePageBenefits = () => {
    const navigate = useNavigate();

    const sectionRef = useRef(null); // Ref pour la section
    const [isVisible, setIsVisible] = useState(false); // Gère l'état de visibilité

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true); // Active l'animation
                }
            },
            { threshold: 0.2 } // L'animation démarre lorsque 20% de la section est visible
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className="bg-gray-50 py-16">
            <HomePageContainer className="flex flex-col space-y-4 py-4 w-full">
                {/* Titre principal */}
                <div className="text-center space-y-2">
                    <Typography variant="h2" weight="bold" className="drop-shadow-md">
                        Pourquoi choisir Bara Doc ?
                    </Typography>
                    <Typography variant="lead" className="text-gray-600">
                        Découvrez les avantages qui simplifieront votre quotidien de RH
                    </Typography>
                </div>

                {/* Carrousel des bénéfices */}
                <BenefitCarousel benefits={benefits} />


                {/* Bouton et sous-texte */}
                <div className="flex flex-col items-center justify-center space-y-4">
                    <Button 
                        variant='primary'
                        size="md"
                        onClick={() => navigate('/document-manager')}
                        className="transform hover:scale-105 transition-transform duration-300"
                    >
                        Commencez dès maintenant
                    </Button>

                    <Typography
                        variant='caption3'
                        theme='gray-600'
                        className='italic'
                    >
                        Testez Bara Doc gratuitement et voyez la différence par vous-même.
                    </Typography>
                </div>
            </HomePageContainer>
        </section>
    );
};

export default HomePageBenefits;
