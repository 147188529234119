import React from 'react';
import Footer from '../../../components/Footer';
import { HomePageContainer } from '../../../components/Containers/HomePageContainer';

function TermsAndConditions() {
    return (
        <div className="bg-primary-200 flex flex-col min-h-screen">
            {/* Conteneur principal qui s'étire pour occuper l'espace disponible */}
            <div className="flex-grow flex flex-col overflow-y-auto">
                <HomePageContainer>
                    <h1 className="text-3xl font-bold text-center mt-6">Terms and Conditions</h1>
                    <p className="text-center text-gray-700 mt-4">
                        This page will outline the terms and conditions for using this application.
                        En cours de développement.
                    </p>
                </HomePageContainer>
            </div>

            {/* Footer sticky en bas de la page */}
            <footer className="bg-black text-white w-full mt-auto">
                <Footer />
            </footer>
        </div>
    );
}

export default TermsAndConditions;
