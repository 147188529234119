import React, { useState } from 'react';
import { useEffect } from 'react';

import SidebarContainer from '../../components/Workspace/SideBar/SideBarContainer';
import DashboardContainer from '../../components/Workspace/Dashboard/DashboardContainer';
import UsersModelsContainer from '../../components/Workspace/UsersModels/UsersModelsContainer';
import LibraryContainer from '../../components/Workspace/Library/LibraryContainer';
import AdminUsersContainer from '../../components/Workspace/AdminUsers/AdminUsersContainer';
import TutorialContainer from '../../components/Workspace/Tutorial/TutorialContainer';
import TeamContainer from '../../components/Workspace/Team/TeamContainer';
import AdminBoardContainer from '../../components/Workspace/AdminBoard/AdminBoardContainer';
import AdminDocsContainer from '../../components/Workspace/AdminDocs/AdminDocsContainer';

import { withRoleProtection } from '../../utils/Utilities';

const ProtectedDashboard = withRoleProtection(DashboardContainer, ['user', 'admin']);
const ProtectedLibrary = withRoleProtection(LibraryContainer, ['user', 'admin']);
const ProtectedAdminUsers = withRoleProtection(AdminUsersContainer, ['admin']);
const ProtectedAdminBoard = withRoleProtection(AdminBoardContainer, ['admin']);

function Workspace() {
    const [activeComponent, setActiveComponent] = useState(() => {
        // On tente de récupérer la valeur depuis le localStorage
        const storedComponent = localStorage.getItem('activeComponent');
        return storedComponent ? storedComponent : 'Tableau de bord';
    });

    // Dès que activeComponent change, on le stocke dans localStorage
    useEffect(() => {
        localStorage.setItem('activeComponent', activeComponent);
    }, [activeComponent]);

    const renderActiveComponent = () => {
        switch (activeComponent) {
            case 'Tableau de bord':
                return <DashboardContainer />;
            case 'Modèles':
                return <UsersModelsContainer />;
            case 'Bibliothèque':
                return <LibraryContainer />;
            case 'Equipe':
                return <TeamContainer />;
            case 'Utilisateurs':
                return <AdminUsersContainer />;
            case 'Tutoriel':
                return <TutorialContainer />;
            case 'AdminBoard':
                return <AdminBoardContainer />;
            case 'AdminDocs':
                return <AdminDocsContainer />;
            default:
                return <DashboardContainer />;
        }
    };

    return (
        <div className="flex h-screen overflow-hidden">
            {/* Sidebar */}
            <SidebarContainer 
                setActiveComponent={setActiveComponent} 
                activeComponent={activeComponent}  // <-- Passez ici la valeur actuelle
                className="h-screen fixed" 
            />
            
            {/* Main Content */}
            <main className="flex-grow p-6 bg-white overflow-auto ">
                {renderActiveComponent()}
            </main>
        </div>
    );
}

export default Workspace;
