import React, { useEffect, useState } from 'react';
import CustomTable from '../../../style/Tables/CustomTable';
import { fetchPublicDocuments } from '../../../../utils/ApiDocsUtilities';

import { FaMagnifyingGlass, FaArrowRightFromBracket } from 'react-icons/fa6';


function LibraryContainer() {
    const [listDocument, setListDocument] = useState([]);
    const [isFetchLoading, setIsFetchLoading] = useState(false);

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                setIsFetchLoading(true);
                const data = await fetchPublicDocuments(); // Appelle la fonction API
                setListDocument(data);
                // console.log("Library data", data)
            } catch (error) {
                console.error('Erreur lors de la récupération des documents:', error);
            } finally {
                setIsFetchLoading(false);
            }
        };
        fetchDocuments();
    }, []);

    if (isFetchLoading) {
        return <div>Chargement des documents...</div>;
    }

    // Définir les colonnes
    const columns = [
        { key: "Nom", title: "Nom", ratio: 2 }, // 2/5
        { key: "Catégorie", title: "Catégorie", ratio: 1 }, // 1/5
        { key: "Date de création", title: "Date de création", ratio: 1 }, // 1/5
      ];
      

    // Formater les données
    const formattedData = listDocument.map((doc) => ({
        Nom: doc.name,
        Catégorie: doc.category,
        "Date de création": new Date(doc.created_at).toLocaleDateString("fr-FR"), // Format de date
      }));

    // Gestion des actions via une colonne fixe
    const handleAction = (action, row) => {
        if (action === 'générer') {
          console.log('Génération doc public', row);
        }
      };
      

    return (
        <section className="flex flex-col px-8 space-y-8">
            <h2 className="text-2xl font-bold mb-4 text-center">Bibliothèque</h2>
            <div className="flex w-full max-h-[70vh] rounded-lg border shadow-md overflow-y-auto">
                <CustomTable 
                    columns={columns} 
                    data={formattedData} 
                    onAction={handleAction}
                    isPublic={true} 
                />
            </div>
        </section>
      );
      
}

export default LibraryContainer;
