import React, { useState, useEffect } from 'react';
import AdminDocsTable from '../AdminDocsTable';
import Button from '../../../style/Button';
import { useUser } from '../../../../context/User/UserContext';
import ConfirmationModal from '../../../style/ConfirmationModal';
import SidePanel from '../../../SidePanelComponents/SidePanel';
import EditDocumentForm from '../../../SidePanelComponents/EditDocumentForm';
import { uploadAndExtractPlaceholders } from '../../../../utils/apiUtilities';
import { fetchPublicDocuments, deleteDocument, updateDocument, getDocument } from '../../../../utils/ApiDocsUtilities';

import ModalAddModel from '../../../style/Modals/ModalAddModel';
import { IoAddOutline } from "react-icons/io5";


function AdminDocsContainer() {
    const { user } = useUser();
    const [models, setModels] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [isConfirming, setIsConfirming] = useState(false); // État pour la modale de confirmation
    const [modelToDelete, setModelToDelete] = useState(null); // Stocke le modèle à supprimer
    // Gestion de l'edition
    const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
    const [modelToEdit, setModelToEdit] = useState(null);

    // Gestion de l'ajout
    const [isModalOpen, setIsModalOpen] = useState(false);


    // Fonction pour charger les documents publics
    const loadPublicDocuments = async () => {
        setLoading(true);
        setError(null);
        try {
            const publicDocuments = await fetchPublicDocuments();
            console.log("Documents publics reçus :", publicDocuments); // Log des données reçues du backend
            setModels(publicDocuments);
        } catch (err) {
            setError("Erreur lors de la récupération des documents publics.");
        } finally {
            setLoading(false);
        }
    };
    

    // Charger les documents publics au montage du composant
    useEffect(() => {
        loadPublicDocuments();
    }, []);



    const handleFileUpload = async () => {
        if (!selectedFile) {
            setError("Veuillez d'abord choisir un fichier.");
            return;
        }

        setLoading(true);
        setError(null);

        
        const isAdmin = user.global_role === "SUPERADMIN" || user.global_role === "ADMIN";
        console.log("Rôle de l'utilisateur :", user.global_role);
        console.log("isAdmin envoyé :", isAdmin);


        try {
            // Passe `isAdmin` en fonction du rôle de l'utilisateur
            const response = await uploadAndExtractPlaceholders(selectedFile, {}, isAdmin);
            console.log("Réponse de l'API après upload:", response); // Affiche la réponse de l'API pour vérifier l'état

            // Recharger la liste des documents publics après l'upload
            await loadPublicDocuments();
            setSelectedFile(null); // Réinitialiser le fichier sélectionné après l'upload réussi
        } catch (err) {
            setError("Erreur lors du traitement du document.");
            console.error("Erreur lors de l'upload:", err);
        } finally {
            setLoading(false);
        }
    };

    // Ouvre la modal d'ajout
    const handleOpenAddModel = () => {
        setIsModalOpen(true);
    };

    // Callback lorsque le document a été uploadé avec succès
    const handleUploadSuccess = async (response) => {
        console.log('Document ajouté avec succès:', response);
        // Ferme la modal
        setIsModalOpen(false);
        // Recharge la liste des documents
        await loadPublicDocuments();
    };

    // Fonction pour demander la confirmation de suppression
    const requestDeleteConfirmation = (model) => {
        setIsConfirming(true);
        setModelToDelete(model);
    };

    // Fonction pour confirmer et effectuer la suppression
    const confirmDelete = async () => {
        if (modelToDelete) {
            try {
                await deleteDocument(modelToDelete.id);
                setModels((prevModels) => prevModels.filter((item) => item.id !== modelToDelete.id));
                console.log("Document supprimé avec succès:", modelToDelete.name);
            } catch (err) {
                console.error("Erreur lors de la suppression du document:", err);
                setError("Une erreur est survenue lors de la suppression du document.");
            } finally {
                setIsConfirming(false);
                setModelToDelete(null);
            }
        }
    };

    const cancelDelete = () => {
        setIsConfirming(false);
        setModelToDelete(null);
    };

    /* -----------------------  Gestion des edition ------------------ */
    const handleEdit = async (model) => {
        try {
            const documentDetails = await getDocument(model.id);
            if (documentDetails && documentDetails.id) { // Vérifie que l'ID est bien défini
                setModelToEdit(documentDetails);
                setIsEditPanelOpen(true);
            } else {
                console.error("Document sans ID récupéré :", documentDetails);
                setError("Erreur lors de la récupération des détails du document.");
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des détails du document :", error);
            setError("Erreur lors de la récupération des détails du document");
        }
    };
    

    const handleSaveEdit = async (updatedModel) => {
        if (!updatedModel.id) {
            console.error("ID du document non défini !");
            return;
        }
    
        console.log("Données envoyées pour la mise à jour :", updatedModel);
    
        try {
            const response = await updateDocument(updatedModel.id, updatedModel);
            console.log("Réponse du backend :", response);
    
            // Recharge la liste complète des documents après la mise à jour
            await loadPublicDocuments();
    
            setIsEditPanelOpen(false); // Ferme le panneau d'édition
        } catch (error) {
            console.error("Erreur lors de la mise à jour :", error);
            setError("Erreur lors de la mise à jour du document.");
        }
    };
    
    
    
    const handleCancelEdit = () => {
        setIsEditPanelOpen(false);
    };

    // Fonction pour les paramètres
    const handleSettings = (model) => {
        console.log("Paramètres du modèle:", model);
        // Logique pour les paramètres du document
    };

    const firstDocument = models[0] || {};

    return (
        <div className="p-4 border rounded">
            <h2 className="text-2xl font-bold mb-4">Modèles administrateur</h2>

            {/* Boutons pour sélectionner un fichier et envoyer */}
            <div className="flex items-center justify-between mb-4">
                {/* Bouton "Ajouter" avec l'icône IoAddOutline */}
                <Button
                    variant="primary"
                    size="sm"
                    icon={IoAddOutline}      // <-- On passe l'icône
                    iconPosition="left"      // <-- Icône à gauche
                    onClick={handleOpenAddModel}
                >
                    Ajouter un modèle
                </Button>

                <button
                    onClick={loadPublicDocuments}
                    className="bg-gray-500 text-white p-2 rounded"
                >
                    Rafraîchir
                </button>
            </div>

            {/* Affichage d'un message de chargement ou d'erreur */}
            {loading && <p>Chargement en cours...</p>}
            {error && <p className="text-red-500">{error}</p>}

            {/* Tableau des modèles pour afficher les documents */}
            <AdminDocsTable
                models={models}
                onEdit={handleEdit}
                onDelete={requestDeleteConfirmation}
                onSettings={handleSettings}
            />

            {/* Modale de confirmation */}
            <ConfirmationModal
                isOpen={isConfirming}
                title="Confirmation de suppression"
                message={`Voulez-vous vraiment supprimer le document "${modelToDelete?.name}" ?`}
                onConfirm={confirmDelete}
                onCancel={cancelDelete}
            />

            {/* Modal d'ajout de document */}
            <ModalAddModel
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onUploadSuccess={handleUploadSuccess}
                isAdmin={true}
            />

            {/* Panneau d'édition */}
            <SidePanel isOpen={isEditPanelOpen} onClose={handleCancelEdit} title="Modifier le document">
                {modelToEdit && (
                    <EditDocumentForm
                        model={modelToEdit}
                        onSave={handleSaveEdit}
                        onCancel={handleCancelEdit}
                    />
                )}
            </SidePanel>
        </div>
    );
}

export default AdminDocsContainer;
